export function getURLParameter(param: string) {
  if (!window.location.search) {
    return;
  }
  var m = new RegExp(param + "=([^&]*)").exec(
    window.location.search.substring(1)
  );
  if (!m) {
    return;
  }
  return decodeURIComponent(m[1]);
}

export function emitEventV2(eventName: string, data: any) {
  const os = getMobileOperatingSystem();
  try {
    if (os === "Android") {
      const androidData = { eventName, data };
      const androidDataStr = JSON.stringify(androidData);
      console.log(androidDataStr);
      if (app) {
        app.callback(androidDataStr);
      }
    } else if (os === "RN") {
      const rn = { eventName, data };
      const rnStr = JSON.stringify(rn);
      console.log(rnStr);
      window["ReactNativeWebView"].postMessage(rnStr);
    } else if(os === "iOS") {
      const iosData = { eventName, data };
      const iosDataStr = JSON.stringify(iosData);
      console.log(iosDataStr);
      if (webkit) {
        webkit.messageHandlers.callback.postMessage(iosDataStr);
      }
    } else if (os === "I_FRAME") {
      const event = { eventName, data };
      const eventStr = JSON.stringify(event);
      console.log(eventStr);
      window.parent.postMessage(eventStr, "*");
    }
  } catch (e) {
    console.log(e);
  }
}

// https://stackoverflow.com/a/21742107/4363426
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor;

  if (window.parent !== window && window.parent.postMessage) {
    return "I_FRAME";
  }

  // Check RN
  if (window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage) {
    return "RN";
  }

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

import { track } from "../../api/analytics";
import { IReward } from "../../interfaces";
import { IContext } from "../interfaces";
import { IRewardActions } from "./actions";
import { fetchRewards, requestConsumeReward } from "./api";
import { createContext, h } from "preact";
import { useContext, useMemo, useReducer } from "preact/hooks";

export interface IRewardsState {
  allIds: string[];
  byId: {
    [id: string]: IReward;
  };
  rewardStatus: {
    [id: string]: "CONSUMING" | "CONSUME_ERROR" | "CONSUME_SUCCESS" | "DEFAULT";
  };
  status: "DEFAULT" | "LOADING" | "LOAD_SUCCESS" | "LOAD_ERROR";
}

export interface IRewardResultParams {
  lost?: boolean;
  timeLeft?: number;
  selectedSlotIndex?: number;
  score?: number;
}

const initialState: IRewardsState = {
  allIds: [],
  byId: {},
  rewardStatus: {},
  status: "DEFAULT",
};

function rewardsReducer(
  state: IRewardsState,
  action: IRewardActions
): IRewardsState {

  if (action.type !== "LOAD REWARDS" && action.type !== "CONSUME REWARD")
    track(action);
  if (action.type === "CONSUME REWARD") {
    const newAction: IRewardActions = {
      ...action,
      payload: {
        ...action.payload,
        updatedReward: null,
      },
    };
    track(newAction);
  }

  switch (action.type) {
    case "LOAD REWARDS": {
      return {
        ...state,
        status: "LOADING",
      };
    }
    case "LOAD REWARDS SUCCESS": {
      return {
        ...state,
        allIds: action.payload.allIds,
        byId: action.payload.byId,
        status: "LOAD_SUCCESS",
      };
    }
    case "LOAD REWARDS ERROR": {
      return {
        ...state,
        status: "LOAD_ERROR",
      };
    }
    case "CONSUME REWARD": {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.updatedReward.rewardUserId]:
            action.payload.updatedReward,
        },
        rewardStatus: {
          [action.payload.updatedReward.rewardUserId]: "CONSUMING",
        },
      };
    }
    case "CONSUME REWARD SUCCESS": {
      return {
        ...state,
        rewardStatus: {
          [action.payload.id]: "CONSUME_SUCCESS",
        },
      };
    }
    case "CONSUME REWARD ERROR": {
      return {
        ...state,
        rewardStatus: {
          [action.payload.id]: "CONSUME_ERROR",
        },
      };
    }
    case "UNCONSUME REWARD": {
      const newIndex = Math.floor(
        Math.random() * state.byId[action.payload.id].layout.data.slots.length
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            experienceConsumed: false,
            selectedSlotIndex: newIndex,
          },
        },
      };
    }
  }
}

const RewardsContext = createContext<IContext<IRewardsState, IRewardActions>>(
  undefined
);

export function RewardsProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<IRewardsState, IRewardActions>(
    rewardsReducer,
    initialState
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <RewardsContext.Provider value={value}>
      {props.children}
    </RewardsContext.Provider>
  );
}

export function useRewards() {
  const context = useContext(RewardsContext);
  if (context === undefined) {
    throw new Error("useRewards must be used within a RewardsProvider");
  }
  return {
    state: context.state,
    dispatch: context.dispatch,
  };
}

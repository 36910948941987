import { createContext, h } from "preact";
import { useContext, useMemo, useReducer } from "preact/hooks";
import { IContext } from "../interfaces";
import { ISessionActions } from "./actions";

export interface ISessionState {
  token: string;
  rewardUserId?: string;
  campaignId?: string;
  activityId?: string;
  streakCount?: string;
}

const initialState: ISessionState = {
  token: null,
  rewardUserId: null,
  campaignId: null,
};

function sessionReducer(
  state: ISessionState,
  action: ISessionActions
): ISessionState {
  switch (action.type) {
    case "SESSION_STORE SET SESSION": {
      return action.payload;
    }
  }
}

const SessionContext = createContext<IContext<ISessionState, ISessionActions>>(
  undefined
);

export function SessionProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<ISessionState, ISessionActions>(
    sessionReducer,
    initialState
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <SessionContext.Provider value={value}>
      {props.children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
}

import { popPageDetails, pushPageDetails, track } from "../../api/analytics";
import { IProgramNudgeWrapperProps } from "../../components/wrappers/nudge-wrapper";
import { IProgramWrapperProps } from "../../components/wrappers/program-wrapper";
import { IRewardWrapperProps } from "../../components/wrappers/reward-wrapper";
import { IWalletWrapperProps } from "../../components/wrappers/wallet-wrapper";
import { emitEventV2 } from "../../util/url";
import { IRouterActions, popPageStart } from "./actions";
import { createContext, h } from "preact";
import { useContext, useEffect, useMemo, useReducer } from "preact/hooks";


export interface IFullPageMode {
  mode: "FULL";
  animation: boolean;
  hideClose?: boolean;
  confetti?: boolean;
}

export interface IModalMode {
  mode: "MODAL";
}

export interface IBottomSheetMode {
  mode: "BOTTOM_SHEET";
}

export interface ISidePanelMode {
  mode: "SIDE_PANEL";
  ancor: "LEFT" | "RIGHT";
}

export type IPageModes = "FULL" | "MODAL" | "BOTTOM_SHEET" | "SIDE_PANEL";

export type IPageConfig =
  | IFullPageMode
  | IModalMode
  | IBottomSheetMode
  | ISidePanelMode;

export type IPageType =
  | "REWARD_WRAPPER"
  | "WALLET_WRAPPER"
  | "PROGRAM_NUDGE_WRAPPER";

export interface IPage {
  props:
    | IRewardWrapperProps
    | IWalletWrapperProps
    | IProgramWrapperProps
    | IProgramNudgeWrapperProps;
  hidePreviousPages?: boolean;
  extraData?:any;
  config: IPageConfig;
  startClose?: boolean;
}

export interface IRouterState {
  allIds: number[];
  byId: {
    [id: number]: IPage;
  };
  currentId: number;
}

const initialState: IRouterState = {
  allIds: [],
  byId: {},
  currentId: 0,
};

function routerReducer(
  state: IRouterState,
  action: IRouterActions
): IRouterState {
  track(action);
  switch (action.type) {
    case "ROUTER_STORE PUSH PAGE": {
      pushPageDetails({ ...action.payload });
      const actionPage = action.payload;
      if (state.allIds.length && actionPage.props.id === "PROGRAM_WRAPPER") {
        const previousSamePageIndex = state.allIds.findIndex((id, index) => {
          const page = state.byId[id];
          if (
            page.props.id === "PROGRAM_WRAPPER" &&
            actionPage.props.id === "PROGRAM_WRAPPER" &&
            page.props.data.campaignId === actionPage.props.data.campaignId &&
            page.props.data.rootId === actionPage.props.data.rootId
          )
            return true;
        });
        if (
          previousSamePageIndex !== -1 &&
          state.allIds[previousSamePageIndex] !== state.currentId
        ) {
          return {
            ...state,
            byId: {
              ...state.byId,
              [state.currentId]: {
                ...state.byId[state.currentId],
                startClose: true,
              },
            },
          };
        }
      }
      const newCurrentId = state.currentId + 1;
      if (history) history.pushState({ page: 1 }, newCurrentId.toString());
      return {
        ...state,
        allIds: [...state.allIds, newCurrentId],
        byId: {
          ...state.byId,
          [newCurrentId]: action.payload,
        },
        currentId: newCurrentId,
      };
    }
    case "ROUTER_STORE POP PAGE": {
      popPageDetails();
      if (state.allIds.length === 1) {
        close();
        return state;
      } else
        return {
          ...state,
          allIds: state.allIds.filter((id) => id !== state.currentId),
          currentId: state.currentId - 1,
        };
    }
    case "ROUTER_STORE POP PAGE START": {
      if (!action.payload.isNative) {
        if (history) {
          history.back();
          window["__ignoreBack__"] = true;
        }
      }
      if (state.allIds.length === 1) {
        close();
        return state;
      } else
        return {
          ...state,
          byId: {
            ...state.byId,
            [state.currentId]: {
              ...state.byId[state.currentId],
              startClose: true,
            },
          },
        };
    }
    case "ROUTER_STORE REPLACE PAGE": {
      return {
        ...state,
        byId: {
          [state.currentId]: action.payload,
        },
      };
    }
  }
}

const RouterContext = createContext<
  | {
      state: IRouterState;
      dispatch: (action: IRouterActions) => void;
    }
  | undefined
>(undefined);

export function RouterProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<IRouterState, IRouterActions>(
    routerReducer,
    initialState
  );

  useEffect(() => {
    window.onpopstate = (e) => {
      if (window["__ignoreBack__"] === true) window["__ignoreBack__"] = false;
      else dispatch(popPageStart({ isNative: true }));
    };
  }, []);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <RouterContext.Provider value={value}>
      {props.children}
    </RouterContext.Provider>
  );
}

export function useRouter() {
  const context = useContext(RouterContext);
  if (context === undefined) {
    throw new Error("useContenxt must be used within a RouterProvider");
  }
  return context;
}

function close () {
  emitEventV2("CLOSE", null);
  if (window.parent !== window) {
    const data: any = {
      target: "GLU_SDK",
      action: {
        type: "CLOSE_FRAME_CONTENT",
        data: {
          rewardsState: null,
          programsState: null
        }
      }
    };
    window.parent.postMessage(data, "*");
  }
}
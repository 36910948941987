interface IPageDetails {
  campaignId?: string;
  rewardUserId?: string;
  config?: any;
  props?: any;
  empty?: boolean;
}

const pageDetailsStack: IPageDetails[] = [];

export function pushPageDetails(details: IPageDetails) {
  pageDetailsStack.push(details);
}

export function popPageDetails() {
  pageDetailsStack.pop();
}

export function setPageDetails(details: Object) {
  const d = pageDetailsStack[pageDetailsStack.length - 1];
  pageDetailsStack[pageDetailsStack.length - 1] = { ...d, ...details };
}

export function getPageDetails(): IPageDetails {
  if (pageDetailsStack.length) {
    return pageDetailsStack[pageDetailsStack.length - 1];
  } else return {
    empty: true
  };
}

export function track(action) {
  const event = {
    timestamp: new Date(),
    event_name: "UI_INTERACTION",
    event_properties: {
      pageContext: getPageDetails(),
      action,
    },
  };
  sendAnalytics(event);
}

export async function sendAnalytics(analytics: any) {
  try {
    await fetch("https://stream.customerglu.com/v3/analytics", {
      method: "post",
      body: JSON.stringify(analytics),
      headers: {
        "Content-Type": "application/json",
        "X-GLU-AUTH": `Bearer ${window["GluToken"]}`,
      },
    });
  } catch (e) {
    console.log(e);
  }
}

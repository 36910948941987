import { IAction } from "../interfaces";
import { IPage } from "./context";

type IPushPage = IAction<"ROUTER", "ROUTER_STORE PUSH PAGE", IPage>;
export function pushPage(payload: IPage): IPushPage {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE PUSH PAGE",
    payload,
  };
}

type IPopPage = IAction<"ROUTER", "ROUTER_STORE POP PAGE", null>;
export function popPage(): IPopPage {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE POP PAGE",
    payload: null,
  };
}

type IPopPageStart = IAction<"ROUTER", "ROUTER_STORE POP PAGE START", { isNative?: true }>;
export function popPageStart(payload: { isNative?: true }): IPopPageStart {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE POP PAGE START",
    payload: payload,
  };
}

type IReplacePage = IAction<"ROUTER", "ROUTER_STORE REPLACE PAGE", IPage>;
export function replacePage(payload: IPage): IReplacePage {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE REPLACE PAGE",
    payload,
  };
}

export type IRouterActions = IPushPage | IPopPage | IReplacePage | IPopPageStart;

import { track } from "../../api/analytics";
import { IProgramState } from "../../interfaces";
import { IContext } from "../interfaces";
import { IProgramActions } from "./actions";
import { createContext, h } from "preact";
import { useContext, useMemo, useReducer } from "preact/hooks";

export interface IProgramsState {
  allIds: string[];
  byId: {
    [id: string]: IProgramState;
  };
  status: "DEFAULT" | "LOADING" | "LOAD_SUCCESS" | "LOAD_ERROR";
  referralLink: string;
}

const initialState: IProgramsState = {
  allIds: [],
  byId: {},
  status: "DEFAULT",
  referralLink: "",
};

function programsReducer(
  state: IProgramsState,
  action: IProgramActions
): IProgramsState {
  if (action.type !== "LOAD PROGRAMS") track(action);
  switch (action.type) {
    case "LOAD PROGRAMS": {
      return {
        ...state,
        status: "LOADING",
      };
    }
    case "LOAD PROGRAMS SUCCESS": {
      return {
        ...state,
        allIds: action.payload.allIds,
        byId: action.payload.byId,
        status: "LOAD_SUCCESS",
        referralLink: action.payload.referralLink,
      };
    }
    case "LOAD PROGRAMS ERROR": {
      return {
        ...state,
        status: "LOAD_ERROR",
      };
    }
  }
}

const ProgramsContext = createContext<
  IContext<IProgramsState, IProgramActions>
>(undefined);

export function ProgramsProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<IProgramsState, IProgramActions>(
    programsReducer,
    initialState
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <ProgramsContext.Provider value={value}>
      {props.children}
    </ProgramsContext.Provider>
  );
}

export function usePrograms() {
  const context = useContext(ProgramsContext);
  if (context === undefined) {
    throw new Error("usePrograms must be used within a ProgramsProvider");
  }
  return {
    state: context.state,
    dispatch: context.dispatch,
  };
}

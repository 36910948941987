import { FunctionalComponent, h } from "preact";
import { useEffect } from "preact/hooks";
import { RouterProvider } from "../stores/page/context";
import { ProgramsProvider } from "../stores/program/context";
import { RewardsProvider } from "../stores/reward/context";
import { SessionProvider } from "../stores/session/context";
import { Router, Route } from "preact-router";
import Reward from "../routes/reward";
import Program from "../routes/program";
import Wallet from "../routes/wallet";
import ProgramNudge from "../routes/program-nudge";
import LauncherButton from "../routes/launcher-button";
import LauncherWallet from "../routes/launcher-wallet";
import Builder1 from "../routes/builder1";
import Builder2 from "../routes/builder2";

const App: FunctionalComponent = () => {
  useEffect(() => {
    console.log("C.C");
  }, []);

  return (
    <div id="app">
      <SessionProvider>
        <RouterProvider>
          <RewardsProvider>
            <ProgramsProvider>
              <Router>
                <Route
                  path="/launcher-button"
                  component={LauncherButton}
                ></Route>
                <Route
                  path="/launcher-wallet"
                  component={LauncherWallet}
                ></Route>
                <Route path="/reward" component={Reward}></Route>
                <Route path="/program" component={Program}></Route>
                <Route path="/wallet" component={Wallet}></Route>
                <Route path="/builder1" component={Builder1}></Route>
                <Route path="/builder2" component={Builder2}></Route>
                <Route
                  path="/program-nudge/:type"
                  component={ProgramNudge}
                ></Route>
              </Router>
            </ProgramsProvider>
          </RewardsProvider>
        </RouterProvider>
      </SessionProvider>
    </div>
  );
};

export default App;
